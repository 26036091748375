import React, { useState } from 'react'
import { Timeline } from 'primereact/timeline'
import moment from 'moment'
import { getAdherenceStatusColor } from '@services/utils'
import { getConsumptionStatusLabel } from './utils'
import { getImpactCheckStatusColor } from './PrnDoses/prnConsumptionUtils'
import AdministrationEventHistoryContent from './DoseConsumptionsHistory/AdministrationEventHistoryContent'
import ImpactCheckHistoryContent from './DoseConsumptionsHistory/ImpactCheckHistoryContent'

// Return history in descending order
const sortHistory = (history) => history
  .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))

function DoseConsumptionsHistory({ historyData = [], timezone }) {
  const [showHistory, setShowHistory] = useState(false)
  const sortedHistory = sortHistory(historyData)

  const getImpactCheckStatusLabel = ({ completedAt, status, effective }) => {
    if (completedAt) {
      return effective ? 'Effective impact' : 'Ineffective impact'
    }

    return status === 'missed' ? 'Missed impact check' : null
  }

  const toggleHistory = () => {
    setShowHistory(!showHistory)
  }

  if (sortedHistory.length === 0) {
    return null
  }

  const customizedMarker = (item) => (
    <span
      className="w-1rem h-1rem border-circle border-solid"
      style={{
        borderColor: item?.isImpactCheck
          ? getImpactCheckStatusColor({ ...item })
          : getAdherenceStatusColor(item.code),
      }}
    />
  )

  const historyContent = (item) => (
    item?.isImpactCheck ? ImpactCheckHistoryContent(item, timezone)
      : AdministrationEventHistoryContent(item, timezone)
  )

  const historyOppositeContent = (item) => (
    <div className="w-6rem px-3 text-sm font-semibold text-left">
      {
        item.isImpactCheck ? getImpactCheckStatusLabel(item)
          : getConsumptionStatusLabel(item.code)
      }
    </div>
  )

  return (
    <div>
      <div className="flex flex-row align-items-center cursor-pointer mt-1 w-4rem" onClick={toggleHistory}>
        <div className="text-xs font-medium font-light text-primary">
          Details
        </div>
        <i className={`text-primary text-sm ${showHistory ? 'pi pi-angle-up' : 'pi pi-angle-down'}`} />
      </div>
      {showHistory && (
        <div className="card px-0">
          <Timeline
            className="flex flex-column align-items-start w-full"
            value={sortedHistory}
            align="left"
            opposite={historyOppositeContent}
            content={historyContent}
            marker={customizedMarker}
          />
        </div>
      )}
    </div>
  )
}

export default DoseConsumptionsHistory
