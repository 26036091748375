import React from 'react'
import CompletedImpactCheckContent from './CompletedImpactCheckContent'
import MissedImpactCheckContent from './MissedImpactCheckContent'

function ImpactCheckHistoryContent(item, timezone) {
  const { completedAt, expectedAt, status } = item

  if (completedAt) {
    return <CompletedImpactCheckContent {...item} timezone={timezone} />
  }

  if (status === 'missed') {
    return <MissedImpactCheckContent expectedAt={expectedAt} timezone={timezone} />
  }

  return null
}

export default ImpactCheckHistoryContent
