import React from 'react'
import moment from 'moment'
import { formatDateTimeLocale } from '@services/utils'

function MissedImpactCheckContent({ expectedAt, timezone }) {
  return (
    <div>
      <div className="text-sm font-normal">Expected:</div>
      <div className="text-xs font-normal">
        {formatDateTimeLocale(moment(expectedAt), timezone)}
      </div>
    </div>
  )
}

export default MissedImpactCheckContent
