import React from 'react'
import moment from 'moment'
import { useCurrentOrganization } from '@components/App'
import { getReasonLabel } from '@components/patients/AdherenceDashboard/Dialog/Form/ReasonDropdown'
import { momentFormats } from '@services/utils/moment'
import { titleize } from '@services/utils/string'
import { getImpactCheckStatusClass } from './PrnDoses/prnConsumptionUtils'

function StatusOverlayContentDetails({
  expectedAt, confirmedBy, confirmedAt, missedReason, refusedReason, status,
  isPrn, prnReason, note, impactChecks, timezone,
}) {
  const { patientLabelSingular } = useCurrentOrganization()

  const missedOrRefused = status === 'missed' || status === 'refused'
  const hasReasons = isPrn || missedOrRefused
  const refusedReasonText = getReasonLabel(patientLabelSingular, 'refused', refusedReason)
  const reason = status === 'missed' ? missedReason : refusedReasonText
  const impactCheck = impactChecks.at(-1)
  const ICClass = impactCheck ? getImpactCheckStatusClass(impactCheck) : null

  const getimpactChecksStatus = () => {
    if (!impactCheck) { return null }

    if (impactCheck.completedAt && impactCheck.effective !== null) {
      return impactCheck.effective ? 'Impact: Effective' : 'Impact: Ineffective'
    }

    return impactCheck.status === 'missed' ? 'Impact Check: Missed' : null
  }

  const impactChecksStatus = getimpactChecksStatus()
  const [impactCheckLabel, impactCheckStatusLabel] = impactChecksStatus ? impactChecksStatus.split(': ') : [null, null]

  return (
    <div className="flex flex-column w-full text-left">
      <div className="flex flex-column mt-2 row-gap-2">
        {expectedAt && (
        <div className="flex flex-row gap-1">
          <span className="text-xs">Expected:</span>
          <span className="text-xs font-medium">{moment(expectedAt).tz(timezone).format(momentFormats.dateYearTime)}</span>
        </div>
        )}
        {confirmedAt && (
        <div className="flex flex-row gap-1">
          <div className="flex flex-row gap-1">
            <span className="text-xs">{`${titleize(status)}:`}</span>
            <span className="text-xs font-medium">{moment(confirmedAt).tz(timezone).format(momentFormats.dateYearTime)}</span>
            <span className="text-xs">by</span>
            <span className="text-xs font-medium">{confirmedBy.fullName}</span>
          </div>
        </div>
        )}
      </div>
      {!hasReasons ? <div className="mt-2" /> : (
        <div className="flex flex-column row-gap-2 mt-3">
          {isPrn && (
          <div className="flex flex-row gap-1">
            <span className="text-xs">PRN Reason: </span>
            <span className="text-xs font-medium">{prnReason || 'Unspecified'}</span>
          </div>
          )}
          {missedOrRefused && (
          <div className="flex flex-row gap-1">
            <span className="text-xs">Reason: </span>
            <span className="text-xs font-medium">{reason || note || 'Unspecified'}</span>
          </div>
          )}
        </div>
      )}
      {impactCheck && (
      <div className="flex flex-column mt-3">
        {impactCheckLabel && (
          <div className="flex flex-row gap-1">
            <span className="text-xs">{impactCheckLabel}</span>
            <div className={`text-xs ${ICClass}`}>{impactCheckStatusLabel}</div>
          </div>
        )}
        {impactCheck.completedAt && (
          <div className="flex flex-row gap-1">
            <span className="text-xs">Feedback: </span>
            <span className="text-xs font-medium w-9">{impactCheck.feedback || 'Unspecified'}</span>
          </div>
        )}
      </div>
      )}
    </div>
  )
}

export default StatusOverlayContentDetails
