import React from 'react'
import moment from 'moment'
import { formatDateTimeLocale } from '@services/utils'

const calculateLateBy = (completedAt, willBeMissedAt) => {
  const minutesPassed = moment(completedAt).diff(moment(willBeMissedAt), 'minutes')
  const getTimeString = (value, unit) => `${value} ${unit}${value === 1 ? '' : 's'}`

  return minutesPassed >= 60 ? getTimeString(Math.floor(minutesPassed / 60), 'hour')
    : getTimeString(minutesPassed, 'minute')
}

function CompletedImpactCheckContent({
  completedAt, completedBy, feedback, status, willBeMissedAt, timezone,
}) {
  return (
    <div>
      <div className="text-sm font-normal">{completedBy.fullName}</div>
      <div className="text-xs font-normal">
        {formatDateTimeLocale(moment(completedAt), timezone)}
      </div>
      {status === 'late' && (
        <div className="text-xs font-normal">
          {`(Late by ${calculateLateBy(completedAt, willBeMissedAt)})`}
        </div>
      )}
      {feedback && (
        <div className="text-xs font-normal">
          Feedback:
          {' '}
          {feedback}
        </div>
      )}
    </div>
  )
}

export default CompletedImpactCheckContent
