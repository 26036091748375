import React, {
  useState, useRef, useCallback,
} from 'react'
import moment from 'moment'
import { OverlayPanel } from 'primereact/overlaypanel'
import { useCurrentOrganization, useCurrentUser } from '@components/App'
import DateTable from '@components/display/DateTable/DateTable'
import { useMarDashboard } from '@hooks/dashboards'
import PRNStatusOverlay from './PRNStatusOverlay'
import MarSigDetails from '../MarSigDetails'
import AdherenceTableHeader from '../AdherenceTableHeader'
import MARApprovalsTable from '../MARApproval/MARApprovalTable'
import ApprovalOverlay from '../MARApproval/ApprovalOverlay'
import { marColumns } from '../utils'
import DoseDetails from '../DoseDetails'
import StatusIndicator from './StatusIndicator'
import MarDialog from '../MarDialog'
import AdherenceLOADialog from '../../AdherenceLOADialog'
import AdherenceDoseHoldDialog from '../../AdherenceDoseHoldDialog'

function PRNConsumptions({
  patientId,
  dateRange,
  marApprovals,
  isApprovalsLoading,
  statusMessageRef,
}) {
  const [selectedConsumptions, setSelectedConsumptions] = useState(null)
  const [selectedMarTimeRow, setSelectedMarTimeRow] = useState(null)
  const [selectedCellTime, setSelectedCellTime] = useState(null)
  const [dialogMode, setDialogMode] = useState()
  const [dialogActiveTabIndex, setDialogActiveTabIndex] = useState(0)
  const [consumptionToBeUpdated, setConsumptionToBeUpdated] = useState(null)
  const [dateHovered, setDateHovered] = useState()

  const { timezone } = useCurrentOrganization()
  const { role: currentUserRole } = useCurrentUser()
  const canChangeMar = ['site_admin', 'caregiver'].includes(currentUserRole)
  const dateMarApprovals = dateHovered ? marApprovals.filter((mar) => mar.days.includes(dateHovered.format('YYYY-MM-DD'))) : []

  const consumptionsOverlay = useRef(null)
  const dateOverlayRef = useRef(null)

  const {
    data: marDashboard,
    isLoading: isMarDashboardLoading,
  } = useMarDashboard(patientId, dateRange, 'prn', statusMessageRef)

  // Show/hide date overlay for MAR approvals
  const showDateOverlay = (date, e) => {
    setDateHovered(date)
    if (dateOverlayRef.current) {
      dateOverlayRef.current.hide()
      requestAnimationFrame(() => {
        dateOverlayRef.current.show(e)
      })
    } else {
      dateOverlayRef.current.show(e)
    }
  }

  const hideDateOverlay = () => {
    if (dateOverlayRef.current) {
      dateOverlayRef.current.hide()
    }
  }

  const onEmptyCellClick = (marTimeRow, cellTime) => {
    setSelectedCellTime(cellTime)
    setSelectedMarTimeRow(marTimeRow)
    setDialogMode('add')
  }

  const onConsumptionsSelect = (consumptions, marTimeRow, cellTime, e) => {
    setSelectedConsumptions(consumptions)
    setSelectedCellTime(cellTime)
    setSelectedMarTimeRow(marTimeRow)
    consumptionsOverlay.current.show(e)
  }

  const onConsumptionEdit = (consumption) => {
    setConsumptionToBeUpdated(consumption)
    if (consumption.status === 'LOA') {
      setDialogMode('LOA')
    } else if (consumption.status === 'on_hold') {
      setDialogMode('hold')
    } else {
      setDialogMode('edit')
    }
    consumptionsOverlay.current.hide()
    setSelectedConsumptions(null)
  }

  const onMarDialogHide = () => {
    setDialogActiveTabIndex(0)
    setConsumptionToBeUpdated(null)
    setSelectedConsumptions(null)
    setDialogMode(null)
  }

  const doseTemplate = (marTimeRow) => (
    <DoseDetails marTimeRow={marTimeRow} />
  )

  const detailsTemplate = (marTimeRow) => {
    const { doseSig } = marTimeRow
    return (
      <MarSigDetails
        doseSig={doseSig}
      />
    )
  }

  const headerTemplate = (date, formattedDate, approvals) => (
    <AdherenceTableHeader
      date={date}
      formattedDate={formattedDate}
      approvals={approvals}
      showDateOverlay={showDateOverlay}
      hideDateOverlay={hideDateOverlay}
    />
  )

  const consumptionTemplate = useCallback((marTimeRow, column) => {
    const consumptions = marTimeRow.consumptionsByDate[column.field]
    const cellTime = moment(`${column.field} ${marTimeRow.time}`, 'YYYY-MM-DD HH:mm A').tz(timezone, true)

    return (
      <StatusIndicator
        consumptions={consumptions || []}
        marTimeRow={marTimeRow}
        cellTime={cellTime}
        timezone={timezone}
        canEdit={canChangeMar}
        onEmptyCellClick={onEmptyCellClick}
        onConsumptionsClick={onConsumptionsSelect}
      />
    )
  }, [onEmptyCellClick])

  const columns = marColumns(
    {
      dateRange,
      marApprovals,
      doseTemplate,
      doseSigTemplate: detailsTemplate,
      headerTemplate,
      consumptionTemplate,
      showTime: false,
    },
  )

  return (
    <>
      <div className="flex flex-column gap-3 text-base text-900 overflow-x-auto" style={{ overflowX: 'auto' }}>
        <DateTable
          data={marDashboard?.marTimeRows || []}
          columns={columns}
          isLoading={isMarDashboardLoading}
          tableClassName="adherence-table"
          emptyMessage="No medications available"
          className="schedule-mar-view"
          sortField="medicine"
          rowGroupMode="rowspan"
          groupRowsBy={['doseSig.dose.id', 'doseSig.id']}
          sortMode="single"
          scrollHeight="70vh"
        />
        <MARApprovalsTable
          marApprovals={marApprovals}
          isLoading={isApprovalsLoading}
        />
      </div>
      <OverlayPanel ref={consumptionsOverlay} className="adherence-status-overlay" dismissable>
        <PRNStatusOverlay
          timezone={timezone}
          consumptions={selectedConsumptions}
          {...(canChangeMar && { handleEdit: onConsumptionEdit })}
        />
      </OverlayPanel>
      <OverlayPanel ref={dateOverlayRef} className="date-overlay">
        <ApprovalOverlay marApprovals={dateMarApprovals} />
      </OverlayPanel>
      {
        canChangeMar && (
          <>
            <MarDialog
              visible={!!dialogMode && (!['LOA', 'hold'].includes(dialogMode))}
              activeTabIndex={dialogActiveTabIndex}
              setActiveTabIndex={setDialogActiveTabIndex}
              onHide={onMarDialogHide}
              consumption={consumptionToBeUpdated}
              cellTime={selectedCellTime}
              marTimeRow={selectedMarTimeRow}
              timezone={timezone}
              mode={dialogMode}
              patientId={patientId}
            />
            <AdherenceLOADialog
              patient={marDashboard?.patient}
              visible={dialogMode === 'LOA'}
              onHide={onMarDialogHide}
              time={consumptionToBeUpdated?.confirmedAt}
              statusMessageRef={statusMessageRef}
              timezone={timezone}
            />
            <AdherenceDoseHoldDialog
              doseId={consumptionToBeUpdated?.doseId}
              visible={dialogMode === 'hold'}
              onHide={onMarDialogHide}
              time={consumptionToBeUpdated?.confirmedAt}
              statusMessageRef={statusMessageRef}
              timezone={timezone}
            />
          </>
        )
      }
    </>
  )
}

export default PRNConsumptions
