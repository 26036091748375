import React from 'react'
import { titleize } from '@services/utils/string'

const getImpactCheckTitle = (impactCheck) => {
  const { status, effective } = impactCheck

  if (status === 'late') {
    const effectiveness = effective ? 'Effective' : 'Ineffective'

    return `Edit ${effectiveness} (${titleize(status)}) Impact Check`
  }

  return `Edit ${titleize(status)} Impact Check`
}

const getConsumptionTitle = (consumption) => (
  consumption ? `Edit ${titleize(consumption.status)} Status` : 'Administer Dose'
)

const getTitleSubtitle = (dialogActiveTabIndex, consumption, impactCheck) => {
  if (dialogActiveTabIndex === 1 && impactCheck) {
    return {
      title: getImpactCheckTitle(impactCheck),
      subTitle: 'Please enter the details of the impact check',
    }
  }

  return {
    title: getConsumptionTitle(consumption),
    subTitle: 'Please enter the details of the consumption',
  }
}

function MarDialogHeader({ dialogActiveTabIndex, consumption, impactCheck }) {
  const { title, subTitle } = getTitleSubtitle(dialogActiveTabIndex, consumption, impactCheck)

  return (
    <div className="flex flex-column align-content-start gap-2">
      <div className="font-bold text-lg">{title}</div>
      <span className="font-normal line-height-2 text-sm">{subTitle}</span>
    </div>
  )
}

export default MarDialogHeader
