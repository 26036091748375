import moment from 'moment'
import { getReasonOptions } from '@components/patients/AdherenceDashboard/Dialog/Form/ReasonDropdown'
import { getCssVariable } from '@services/utils'

const OVERRIDE_STATUS_MAP = {
  CREATE_CONFIRMED: 'OVERRIDE_CONFIRMED',
  CREATE_REFUSED: 'OVERRIDE_REFUSED',
}

function getImpactCheckStatusColor({ completedAt, effective }) {
  if (completedAt && effective) {
    return getCssVariable('--text-color')
  }

  return getCssVariable('--circle-pending')
}

const getImpactCheckStatusClass = ({ completedAt, effective }) => {
  if (completedAt && effective) {
    return 'font-bold'
  }

  return 'color-ineffective-missed'
}

const insertImpactCheckIntoHistory = ({ administrationEvents, impactChecks }) => {
  if (!administrationEvents) return []
  const impactCheck = impactChecks.at(-1)
  if (!impactCheck || impactCheck.status === 'pending') return administrationEvents

  const historyImpactCheck = { ...impactCheck, isImpactCheck: true }
  const dateToCompare = impactCheck.completedAt ? moment(impactCheck.completedAt)
    : moment(impactCheck.expectedAt)

  let insertionIndex = administrationEvents.findIndex((el) => dateToCompare.isAfter(el.recordedAt))
  if (insertionIndex === -1) insertionIndex = administrationEvents.length

  return [
    ...administrationEvents.slice(0, insertionIndex),
    historyImpactCheck,
    ...administrationEvents.slice(insertionIndex),
  ]
}

const buildDateAndTimeWithTimeZone = ({ date, time, timezone }) => {
  const dateTimeString = `${moment(date).format('YYYY-MM-DD')} ${moment(time, 'hh:mm A').format('HH:mm:ss')}`
  const dateTimeInTimeZone = moment(dateTimeString).tz(timezone, true)
  return dateTimeInTimeZone.toISOString()
}

const createConsumptionPayload = ({
  formData,
  isAddingPrn,
  isOverriding,
  selectedCellTime,
  organization: { timezone, patientLabelSingular },
  dose,
  doseSig,
  patientId,
}) => {
  const {
    administeredBy, status, dateTime, time, prnReason, reason, note,
  } = formData

  const basePayload = {
    code: isOverriding ? OVERRIDE_STATUS_MAP[status] : status,
    subject_id: administeredBy.id,
    prn: true,
    prn_reason: prnReason,
    reason: getReasonOptions(patientLabelSingular, 'refused')
      .find((r) => r.value === reason)?.label,
    note,
    patientId,
  }

  if (isAddingPrn) {
    return {
      consumption: {
        ...basePayload,
        confirmed_at: buildDateAndTimeWithTimeZone(
          { date: selectedCellTime, time, timezone },
        ),
        doseId: dose.id,
        doseSigId: doseSig?.id,
      },
    }
  }

  if (isOverriding) {
    return {
      ...basePayload,
      timestamp: buildDateAndTimeWithTimeZone(
        { date: dateTime, time: dateTime, timezone },
      ),
    }
  }

  return null
}

const convertToOrgTimezone = (date, timezone) => moment.tz(date, timezone).toISOString()

export {
  getImpactCheckStatusColor,
  getImpactCheckStatusClass,
  insertImpactCheckIntoHistory,
  createConsumptionPayload,
  buildDateAndTimeWithTimeZone,
  convertToOrgTimezone,
}
