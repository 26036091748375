import React from 'react'
import { Button } from 'primereact/button'
import { Tooltip } from 'primereact/tooltip'

const getStatusInfo = (status) => {
  if (!status) return {}

  switch (status) {
    case 'confirmed':
      return {
        label: 'Confirmed',
        iconClass: 'p-bg-circle-given',
      }
    case 'refused':
      return {
        label: 'Refused',
        iconClass: 'p-bg-circle-refusal',
      }
    case 'missed':
      return {
        label: 'Missed',
        iconClass: 'p-bg-circle-missed',
      }
    case 'LOA':
      return {
        label: 'Leave of Absence',
        iconClass: 'p-bg-circle-loa',
      }
    case 'on_hold':
      return {
        label: 'On Hold',
        iconClass: 'p-bg-circle-on-hold',
      }
    default:
      return {
        label: 'Unspecified',
        iconClass: 'p-bg-circle-unspecified',
      }
  }
}

const getImpactCheckTitleStatus = ({ completedAt, effective }) => {
  if (completedAt === null) return {}

  let impactCheckIcon
  let impactCheckIconTooltip

  if (effective) {
    impactCheckIcon = 'pi-thumbs-up font-bold'
    impactCheckIconTooltip = 'Effective'
  } else {
    impactCheckIcon = 'pi-thumbs-down color-ineffective-missed'
    impactCheckIconTooltip = 'Ineffective'
  }

  return { impactCheckIcon, impactCheckIconTooltip }
}

function StatusOverlayContentHeader({ consumption, handleEdit }) {
  const { status, impactChecks } = consumption
  const impactCheck = impactChecks.at(-1)
  const { label, iconClass } = getStatusInfo(status)
  const { impactCheckIcon, impactCheckIconTooltip } = impactCheck
    ? getImpactCheckTitleStatus(impactCheck) : {}

  return (
    <div className="flex flex-row align-items-center">
      <div className="flex flex-row gap-2">
        <i className={`pi pi-circle-fill ${iconClass}`} />
        <div className="text-base font-medium line-height-2">{label}</div>
        {impactCheck && (
          <div>
            <Tooltip target=".impact-check-icon" />
            <i className={`impact-check-icon pi ${impactCheckIcon}`} data-pr-tooltip={impactCheckIconTooltip} />
          </div>
        )}
      </div>
      { handleEdit && (
        <Button label="Edit" className="p-button-sm p-button-text text-xs p-0 ml-auto" onClick={() => handleEdit(consumption)} />
      )}
    </div>
  )
}

export default StatusOverlayContentHeader
