/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { InputTextarea } from 'primereact/inputtextarea'
import { useUpdateImpactCheck } from '@hooks/impactChecks'

function ImpactCheckForm({
  impactCheck,
  onHide,
  statusMessage,
}) {
  const { id, effective, feedback } = impactCheck
  const defaultValues = { effective: effective?.toString(), feedback: feedback ?? '' }

  const { handleSubmit, control, watch } = useForm({ defaultValues })
  const watchFeedback = watch('feedback')
  const effectiveOptions = [
    { value: 'true', label: 'Effective' },
    { value: 'false', label: 'Ineffective' },
  ]

  const {
    mutateAsync: updateImpactCheck,
    isLoading: updateIsLoading,
  } = useUpdateImpactCheck(id, statusMessage)

  const onSubmit = async (formData) => {
    const payload = {
      effective: formData.effective === 'true',
      feedback: formData.feedback,
    }

    await updateImpactCheck(payload, {
      onSuccess: () => {
        onHide()
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column w-full gap-4">
      <div className="flex flex-row flex-wrap justify-content-between gap-3">
        <div className="flex flex-column align-content-start gap-2">
          <label htmlFor="effective">Effectiveness</label>
          <Controller
            name="effective"
            control={control}
            rules={{ required: 'Effectiveness is required' }}
            render={({ field }) => (
              <Dropdown
                id="effective"
                className="h-3rem"
                value={field.value}
                onChange={(e) => field.onChange(e.value)}
                options={effectiveOptions}
                optionLabel="label"
                placeholder="Select Effectiveness"
              />
            )}
          />
        </div>
      </div>
      <div className="flex flex-column align-content-start gap-2">
        <label htmlFor="feedback">Feedback</label>
        <Controller
          name="feedback"
          control={control}
          defaultValue={feedback}
          rules={{ required: 'Feedback is required' }}
          render={({ field }) => (
            <InputTextarea
              id="feedback"
              {...field}
            />
          )}
        />
      </div>
      <div className="flex w-full justify-content-center">
        <Button type="submit" label="Save" className="p-button-sm w-full mt-2" disabled={!watchFeedback}>
          {updateIsLoading && <i className="pi pi-spin pi-spinner text-lg" />}
        </Button>
      </div>
    </form>
  )
}

export default ImpactCheckForm
