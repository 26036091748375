import React from 'react'
import moment from 'moment'
import { formatDateTimeLocale } from '@services/utils'

function AdministrationEventHistoryContent(item, timezone) {
  const {
    createdAt, recordedAt, subject, note,
  } = item

  return (
    <div>
      <div className="text-sm font-normal">{subject?.fullName}</div>
      <div className="text-xs font-normal">{`Recorded: ${formatDateTimeLocale(moment(createdAt), timezone)}`}</div>
      <div className="text-xs font-normal">{`Time: ${formatDateTimeLocale(moment(recordedAt), timezone)}`}</div>
      {
        note && (
          <div className="text-xs font-normal">
            Note:
            {' '}
            {note}
          </div>
        )
      }
    </div>
  )
}

export default AdministrationEventHistoryContent
