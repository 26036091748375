import React, { useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { Toast } from 'primereact/toast'
import { TabView, TabPanel } from 'primereact/tabview'
import MarDialogHeader from './MarDialogHeader'
import MarAdherenceForm from './MarAdherenceForm'
import ImpactCheckForm from './PrnDoses/ImpactCheckForm'

function MarDialog({
  visible,
  activeTabIndex,
  setActiveTabIndex,
  onHide,
  consumption,
  cellTime,
  marTimeRow,
  timezone,
  mode,
  patientId,
}) {
  const statusMessage = useRef(null)
  const impactCheck = consumption?.impactChecks?.at(-1)

  if (!cellTime || !marTimeRow) {
    return null
  }

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header={(
        <MarDialogHeader
          dialogActiveTabIndex={activeTabIndex}
          consumption={consumption}
          impactCheck={impactCheck}
        />
      )}
      className="w-30rem"
      draggable={false}
    >
      <Toast ref={statusMessage} />
      <div className="flex flex-column w-full gap-4">
        { impactCheck ? (
          <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
            <TabPanel header="Consumption">
              <MarAdherenceForm
                onHide={onHide}
                consumption={consumption}
                cellTime={cellTime}
                marTimeRow={marTimeRow}
                timezone={timezone}
                mode={mode}
                patientId={patientId}
                statusMessage={statusMessage}
              />
            </TabPanel>
            <TabPanel header="Impact Check">
              <ImpactCheckForm
                impactCheck={impactCheck}
                onHide={onHide}
                statusMessage={statusMessage}
              />
            </TabPanel>
          </TabView>
        ) : (
          <MarAdherenceForm
            onHide={onHide}
            consumption={consumption}
            cellTime={cellTime}
            marTimeRow={marTimeRow}
            timezone={timezone}
            mode={mode}
            patientId={patientId}
            statusMessage={statusMessage}
          />
        )}
      </div>
    </Dialog>
  )
}

export default MarDialog
