import { useMutation, useQueryClient } from '@tanstack/react-query'
import ApiClient from '@services/ApiClient'
import { displayErrorMessages } from '@services/errorHandler'

export function useUpdateImpactCheck(impactCheckId, statusMessage) {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['updateImpactCheck', impactCheckId],
    mutationFn: (body) => ApiClient.patch(`/impact_checks/${impactCheckId}`, body),
    onSuccess: () => {
      queryClient.invalidateQueries('patientDoses')
    },
    onError: (error) => {
      displayErrorMessages(error, statusMessage)
    },
  })
}

export default useUpdateImpactCheck
